import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import LoaderButton from '@paypr/mui5-common-components/dist/components/forms/LoaderButton';
import NumberField from '@paypr/mui5-common-components/dist/components/forms/NumberField';
import DetailContainer from '@paypr/mui5-common-components/dist/components/typography/DetailContainer';
import DetailError from '@paypr/mui5-common-components/dist/components/typography/DetailError';
import DetailField from '@paypr/mui5-common-components/dist/components/typography/DetailField';
import DetailText from '@paypr/mui5-common-components/dist/components/typography/DetailText';
import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { useFormFields } from '../../hooks/forms';
import { isNotBlank, isTextPositiveFloat, trimToNull } from '../../utils/strings';
import {
  buildRedemptionFormMessage,
  calcRedemptionFormDefaultEffectiveDate,
  redemptionFormDefaultMessage,
  redemptionFormSubject,
} from './redemptionFormHelpers';

export interface RedemptionRequestFormProps {
  defaultName?: string;
  defaultEmailAddress?: string;
  onSubmitRequest: (requestForm: RedemptionFormRequest) => void;
  loading?: boolean;
}

export interface RedemptionFormRequest {
  name: string;
  emailAddress: string;
  message: string;
}

export interface RedemptionRequestFormFields {
  name: string;
  emailAddress: string;
  redeemSpecificAmount: string;
  amount: string;
  effectiveOn: DateTime;
  message: string;
}

const RedemptionRequestForm = ({
  defaultName,
  defaultEmailAddress,
  onSubmitRequest,
  loading,
}: RedemptionRequestFormProps) => {
  const defaultEffectiveDate = calcRedemptionFormDefaultEffectiveDate();
  const { fields, handleFieldChange, setFieldValue, resetFields } = useFormFields<RedemptionRequestFormFields>({
    name: defaultName || '',
    emailAddress: defaultEmailAddress || '',
    redeemSpecificAmount: 'true',
    amount: '',
    effectiveOn: defaultEffectiveDate,
    message: redemptionFormDefaultMessage,
  });
  const [effectiveOnHasError, setEffectiveOnHasError] = React.useState(false);

  useEffect(() => {
    if (fields.message !== redemptionFormDefaultMessage) {
      return;
    }

    resetFields();
  }, [defaultName, defaultEmailAddress]);

  const validateForm = () =>
    isNotBlank(fields.name) &&
    isNotBlank(fields.emailAddress) &&
    isNotBlank(fields.message) &&
    (fields.redeemSpecificAmount === 'false' || isTextPositiveFloat(fields.amount)) &&
    !effectiveOnHasError;

  const handleRedeemSpecificAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const redeemSpecificAmount = event.target.value === 'true';
    setFieldValue('redeemSpecificAmount', redeemSpecificAmount ? 'true' : 'false');
  };

  const shouldDisableDate = (date: DateTime) => date.toMillis() !== date.endOf('month').startOf('day').toMillis();

  const handleEffectiveAtChange = (newEffectiveOn: DateTime) => {
    setFieldValue('effectiveOn', newEffectiveOn);
  };

  const handleEffectiveAtError = (error: string | null) => {
    setEffectiveOnHasError(Boolean(error));
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    if (!validateForm()) {
      return;
    }

    onSubmitRequest({
      name: fields.name,
      emailAddress: fields.emailAddress,
      message: buildRedemptionFormMessage({
        message: fields.message,
        amount: fields.redeemSpecificAmount === 'true' ? parseFloat(fields.amount) : null,
        effectiveOn: fields.effectiveOn,
      }),
    });
  };

  const mailtoLink = `mailto:support@norriocapital.com?subject=${encodeURIComponent(
    redemptionFormSubject,
  )}&body=${encodeURIComponent(redemptionFormDefaultMessage)}`;
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} lg={8}>
        <Paper>
          <DetailContainer>
            <DetailField>
              <DetailText>
                To request a copy of the redemption request form, you can send an email to us at{' '}
                <Link href={mailtoLink}>support@norriocapital.com</Link> or submit the form below. Certain limitations
                may apply per the Limited Partnership Agreement executed by the parties.
              </DetailText>
            </DetailField>
            <Grid container justifyContent="justify" spacing={2}>
              <Grid item xs={12} sm={6}>
                <DetailField>
                  <TextField
                    name="name"
                    label="Your Name"
                    value={fields.name}
                    autoComplete="name"
                    required
                    onChange={handleFieldChange}
                    disabled={loading}
                  />
                </DetailField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DetailField>
                  <TextField
                    name="emailAddress"
                    label="Your Email Address"
                    value={fields.emailAddress}
                    autoComplete="email"
                    required
                    onChange={handleFieldChange}
                    disabled={loading}
                  />
                </DetailField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DetailField>
                  <RadioGroup
                    name="redeemSpecificAmount"
                    row
                    onChange={handleRedeemSpecificAmountChange}
                    value={fields.redeemSpecificAmount}
                  >
                    <FormControlLabel
                      radioGroup="redeemSpecificAmount"
                      value="true"
                      control={<Radio />}
                      label="Redeem Specific Amount"
                      disabled={loading}
                    />
                    <FormControlLabel
                      radioGroup="useStandardTimeframe"
                      value="false"
                      control={<Radio />}
                      label="Redeem Full Account Value"
                      disabled={loading}
                    />
                  </RadioGroup>
                  {fields.redeemSpecificAmount === 'true' && (
                    <NumberField
                      name="amount"
                      label="Amount"
                      autoComplete="off"
                      value={fields.amount}
                      min={0}
                      required
                      onChange={handleFieldChange}
                    />
                  )}
                  {trimToNull(fields.amount) !== null && !isTextPositiveFloat(fields.amount) ? (
                    <DetailError>Amount must be a valid number greater than 0</DetailError>
                  ) : null}
                </DetailField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DetailField>
                  <DatePicker
                    label="Effective Date"
                    value={fields.effectiveOn}
                    onChange={handleEffectiveAtChange}
                    minDate={defaultEffectiveDate}
                    maxDate={defaultEffectiveDate.plus({ year: 1 })}
                    shouldDisableDate={shouldDisableDate}
                    onError={handleEffectiveAtError}
                    slotProps={{
                      textField: {
                        helperText:
                          'Effective date must be the end of the month, at least one month in advance. Other restrictions may apply based on LP agreements.',
                      },
                    }}
                    disabled={loading}
                  />
                </DetailField>
              </Grid>
            </Grid>
            <DetailField>
              <TextField
                name="message"
                label="Message"
                value={fields.message}
                autoComplete="off"
                rows={6}
                multiline
                required
                onChange={handleFieldChange}
                disabled={loading}
              />
            </DetailField>
            <DetailField textAlign="right">
              <Button color="inherit" variant="text" disabled={loading} onClick={() => resetFields()}>
                Clear
              </Button>
              &nbsp;
              <LoaderButton loading={loading} disabled={!validateForm()} onClick={handleSubmit}>
                Submit Request
              </LoaderButton>
            </DetailField>
          </DetailContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};
export default RedemptionRequestForm;
