import { DateTime } from 'luxon';
import { formatUsd } from '../common/typography/UsdFormat';

export const redemptionFormSubject = 'Redemption Request Form Request';
export const redemptionFormDefaultMessage = 'Please send me a redemption request form.';

interface BuildRedemptionFormMessageInput {
  message: string;
  amount: number | null;
  effectiveOn: DateTime;
}

export const buildRedemptionFormMessage = ({
  message,
  amount,
  effectiveOn,
}: BuildRedemptionFormMessageInput) => `${message}

Details:
Amount: ${amount === null ? 'Full account value' : formatUsd(amount)}
Effective Date: ${effectiveOn.toLocaleString(DateTime.DATE_SHORT)}`;

export const calcRedemptionFormDefaultEffectiveDate = () =>
  DateTime.now().plus({ month: 1 }).endOf('month').startOf('day');
